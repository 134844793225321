/**
 * Video Series.
 * Determine if the media in a player is part of a video series. If
 * the media is part of a series, then the series name is assigned.
 * In the GA Account this is labeled as `Video Series`.
 * Custom Dimension: 25.
 *
 * @return {object} The object shaped for setting custom dimensions based on the key.
 */
export default function playerMediaSeries() {
    // @ToDo: This will be the series name which is set as a custom
    //        field in BCOV. Need to confirm this with the client.
    return {
        dimension25: '',
    };
}
