/**
 * Player Name.
 * In the GA Account this is labeled as `Video Player Name`.
 * Custom Dimension: 28.
 *
 * @param {mixed} name The player name.
 * @return {object} The object shaped for setting custom dimensions based on the key.
 */
export default function playerName(name) {
    if (name === void 0) { name = null; }
    return {
        dimension28: name,
    };
}
