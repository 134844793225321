import createSimpleGetterSetter from '@/client/js/utils/getterSetter';
/**
 * Set the state of the player and provide sensible default property values.
 *
 * @type {{set: function(*): void, get: function(): Any, update: function(*): void, value: Any}|*}
 */
// eslint-disable-next-line import/prefer-default-export
export var playerState = createSimpleGetterSetter({
    playCount: 0, // Used for setting GA CustomDimension 38.
});
