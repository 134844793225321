/**
 * Media Tags.
 * All the tags that are assigned to the media.
 * In the GA Account this is labeled as `Video Tags`.
 * Custom Dimension: 24.
 *
 * @param {Object} mediaObject The media loaded in to the player.
 * @return {object} The object shaped for setting custom dimensions based on the key.
 */
export default function mediaTags(mediaObject) {
    var _a;
    if (mediaObject === void 0) { mediaObject = {}; }
    var tags = '';
    // Assign the tags from different shapes of the
    // mediaobject received from JW Player API.
    if (typeof (mediaObject === null || mediaObject === void 0 ? void 0 : mediaObject.tags) !== 'undefined') {
        tags = mediaObject.tags;
    }
    else if (typeof ((_a = mediaObject === null || mediaObject === void 0 ? void 0 : mediaObject.item) === null || _a === void 0 ? void 0 : _a.tags) !== 'undefined') {
        tags = mediaObject.item.tags;
    }
    return {
        dimension24: tags,
    };
}
