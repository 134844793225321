var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * Events:
 *  Ad Mode Begin
 *  Ad Start
 *  Ad End
 *  Ad Error
 */
import { gaSendStandardEvent } from '@/platform/jw-player/client/js/analytics/gaActions';
import { mediaState } from '@/platform/jw-player/client/js/analytics/state/mediaState';
import { cdState } from '@/platform/jw-player/client/js/analytics/state/customDimensionState';
import playerAdStatus from '@/platform/jw-player/client/js/analytics/customDimensions/playerAdStatus';
import adResponseError from '@/platform/jw-player/client/js/analytics/customDimensions/adResponseError';
/**
 * Integrate analytics on JW Player Ad Events.
 *
 * @param player The JW Player instance.
 */
export default function adEvent(player) {
    // Fires when the ad manager is loaded into the player.
    // Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronadsmanager
    // @ToDo: This event will more than likely be triggered AFTER the jwplayer().ready event when the
    //        Player Load event is fired. This custom dimension may be set too late at this point. We
    //        will need to revisit this logic once the ads are integrated.
    player.on('adsManager', function () {
        cdState.update(__assign({}, playerAdStatus('yes')));
    });
    // Fires when the VAST XML has been parsed, loaded, and is ready for display.
    // Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronaditem
    player.on('adItem', function () {
        var media = mediaState.get();
        // Update media tracker for custom dimensions.
        mediaState.update({
            adCount: media.adCount + 1,
            isAdEnabled: true,
        });
    });
    // Fires when an ad has started.
    // Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronadstarted
    player.on('adStarted', function () {
        gaSendStandardEvent('Ad Start');
    });
    // Fires when an ad is completed.
    // Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronadcomplete
    player.on('adComplete', function () {
        gaSendStandardEvent('Ad End');
    });
    // Fires when an ad has errored.
    // https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronaderror
    player.on('adError', function () {
        gaSendStandardEvent('Ad Error');
    });
    /**
     * Set the dimension for when an Ad Error occurred.
     * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronaderror
     *
     * |=================================================================================|
     * | Possible Error Messages | Causes                                                |
     * |=================================================================================|
     * | ad tag empty            | No ad was available after searching wrapped ad tags   |
     * |---------------------------------------------------------------------------------|
     * | error playing creative  | 404 on a creative file                                |
     * |---------------------------------------------------------------------------------|
     * | error loading ad tag    | All additional ad errors                              |
     * |---------------------------------------------------------------------------------|
     * | invalid ad tag          | Invalid XML, Improperly formatted VAST syntax         |
     * |---------------------------------------------------------------------------------|
     * | no compatible creatives | FLV video creative or VPAID SWF is attempting to play |
     * |                         | in HTML5 player                                       |
     * |---------------------------------------------------------------------------------|
     */
    player.on('adError', function (message) {
        var adCustomDimension = adResponseError(message);
        cdState.update(__assign({}, adCustomDimension));
    });
    /**
     * Reset a failed message in subsequent player loads.
     * (Needs a reset in case an `adError` event was previously triggered).
     * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronaditem
     */
    player.on('adItem', function () {
        var adCustomDimension = adResponseError(null);
        cdState.update(__assign({}, adCustomDimension));
    });
}
