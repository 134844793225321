var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import mediaId from '@/platform/jw-player/client/js/analytics/customDimensions/mediaId';
import { cdState } from '@/platform/jw-player/client/js/analytics/state/customDimensionState';
import playerMediaSeries from '@/platform/jw-player/client/js/analytics/customDimensions/playerMediaSeries';
import playerName from '@/platform/jw-player/client/js/analytics/customDimensions/playerName';
import playerAdStatus from '@/platform/jw-player/client/js/analytics/customDimensions/playerAdStatus';
import playerAutoplayStatus from '@/platform/jw-player/client/js/analytics/customDimensions/playerAutoplayStatus';
import playerEmbedLocation from '@/platform/jw-player/client/js/analytics/customDimensions/playerEmbedLocation';
import mediaPublishDate from '@/platform/jw-player/client/js/analytics/customDimensions/mediaPublishDate';
import mediaTags from '@/platform/jw-player/client/js/analytics/customDimensions/mediaTags';
import playerType from '@/platform/jw-player/client/js/analytics/customDimensions/playerType';
import playerMediaStartCount from '@/platform/jw-player/client/js/analytics/customDimensions/playerMediaStartCount';
import { mediaState } from '@/platform/jw-player/client/js/analytics/state/mediaState';
/**
 * Set the custom dimensions based on the window.gtag method.
 *
 * @param {object} dimensions The custom dimensions to set.
 */
export function setCustomDimensions(dimensions) {
    if (dimensions === void 0) { dimensions = {}; }
    // GA4.
    if (typeof window.gtag !== 'undefined'
        && typeof dimensions !== 'undefined'
        && Object.keys(dimensions).length) {
        var ga4DimKeys = {
            dimension22: 'video_id',
            dimension23: 'video_publish_date',
            dimension24: 'video_tags',
            dimension25: 'video_series',
            dimension26: 'video_player_type',
            dimension27: 'video_ad_status',
            dimension28: 'video_player_name',
            dimension33: 'video_ad_response_error',
            dimension35: 'video_player_ad_status',
            dimension36: 'video_player_autoplay_status',
            dimension37: 'video_player_timeout_action',
            dimension38: 'video_player_video_start_count',
            dimension39: 'video_player_ad_start_count',
            dimension41: 'video_ad_source',
            dimension42: 'video_ad_errors_before_start',
            dimension43: 'video_embed_location',
        };
        window.ga4Dims = window.ga4Dims || {};
        for (var _i = 0, _a = Object.entries(dimensions); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (ga4DimKeys[key]) {
                window.ga4Dims[ga4DimKeys[key]] = value;
            }
        }
    }
}
/**
 * Clear Google Custom Dimensions that apply to the media.
 * The player is always the same so those dimensions are
 * not required to update when new media is loaded.
 */
export function clearMediaCustomDimensions() {
    var dimensions = cdState.get();
    if (dimensions === null || dimensions === void 0 ? void 0 : dimensions.media) {
        Object
            .keys(dimensions.media)
            .forEach(function (key) {
            dimensions.media[key] = null;
        });
    }
    // Send the `set` method to nullify the dimensions.
    setCustomDimensions(dimensions === null || dimensions === void 0 ? void 0 : dimensions.media);
}
/**
 * Set the custom dimensions in the player's state and in Google Analytics.
 *
 * @param player The JW Player player object.
 * @param config The config object of properties passed in to the plugin.
 */
export function setPlayerCustomDimension(player, config) {
    /**
     * Player Custom Dimensions.
     * -------------------------
     * 25: 'Video Series'
     * 28: 'Video Player Name'
     * 35: 'Video Player Ad Status'
     * 36: 'Video Player Autoplay Status'
     * 43: 'Video Embed Location'
     * -------------------------
     */
    var playerDimensions = __assign(__assign(__assign(__assign(__assign({}, playerMediaSeries()), playerName(config.playerId)), playerAdStatus()), playerAutoplayStatus(player)), playerEmbedLocation(player));
    // Update the state of the dimensions.
    cdState.update({
        player: playerDimensions,
    });
    // Set the dimensions.
    setCustomDimensions(playerDimensions);
}
/**
 * Set the custom dimensions in the media state and in Google Analytics.
 */
export function setMediaCustomDimensions() {
    var media = mediaState.get();
    /**
     * Media Custom Dimensions.
     * -------------------------
     * 22: 'Media ID'
     * 23: 'Publish Date'
     * 24: 'Media Tags'
     * 26: 'Player Type'
     * 38: 'Player Ad Start Count'
     * -------------------------
     */
    var mediaDimensions = __assign(__assign(__assign(__assign(__assign({}, mediaId(media)), mediaPublishDate(media)), mediaTags(media)), playerType()), playerMediaStartCount());
    // Update the state of the dimensions.
    cdState.update({
        media: mediaDimensions,
    });
    // Set the dimensions.
    setCustomDimensions(mediaDimensions);
}
