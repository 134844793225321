/**
 * Events:
 *  Video Impression (Video Load Start)
 *  Video View
 *  Video Pause
 *  Video Resume
 *  Video Engagement / Quartile 25%
 *  Video Engagement / Quartile 50%
 *  Video Engagement / Quartile 75%
 *  Video Engagement / Quartile 100%
 *  Video Fullscreen
 *  Video Windowscreen
 *  Video Volume
 *  Share Overlay
 *  Share Social Clicked
 *  Share Link Copied (unavailable)
 *  Share Embed Copied (unavailable)
 */
import { gaSendStandardEvent, } from '@/platform/jw-player/client/js/analytics/gaActions';
import { mediaState } from '@/platform/jw-player/client/js/analytics/state/mediaState';
/**
 * Integrate analytics on JW Player Media Events.
 *
 * @param {object} player The JW Player instance.
 */
export default function mediaEvent(player) {
    /**
     * Fired when the player begins play. Reasons for play:
     *   - autostart
     *   - external (API usage)
     *   - interaction (click, touch, keyboard)
     *   - playlist (due to autoadvancing)
     *   - related-auto (auto-advancing JW Recommendations playlist)
     *   - related-interaction (interaction to go to a JW Recommendations playlist item)
     *   - viewable (for autopause)
     * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronplay
     */
    player.on('play', function () {
        var mediaStateObject = mediaState.get();
        // Trigger a video view only once per play in the beginning.
        // Apply condition this play was not stemming from a paused state.
        if ((mediaStateObject === null || mediaStateObject === void 0 ? void 0 : mediaStateObject.isPaused) !== true) {
            gaSendStandardEvent('Video View');
        }
        else {
            gaSendStandardEvent('Video Resume');
        }
        // Set the state that the player is not paused.
        mediaState.update({ isPaused: false });
    });
    /**
     * Fires when the user pauses the player.
     * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronpause
     */
    player.on('pause', function () {
        // Update the paused state of the player.
        mediaState.update({ isPaused: true });
        // Set the pause state.
        gaSendStandardEvent('Video Pause');
    });
    /**
     * Fires while the player is playing.
     * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayerontime
     *
     * @param {object} timeObj An object containing the `duration` of the current playlist item in
     *                         seconds, the playback `position` in seconds, and whether the player
     *                         is `viewable`.
     */
    var timeCount = 0;
    player.on('time', function (timeObj) {
        // Increment our counter.
        timeCount += 1;
        // This event is fired up to 10x per second.
        // For assumed efficiency we are going to pump the brakes on
        // applying our logic. Technically, we just run this at least
        // twice per second.
        if (timeCount % 5) {
            var currentMedia = mediaState.get();
            if (typeof (currentMedia === null || currentMedia === void 0 ? void 0 : currentMedia.quartile) !== 'undefined'
                && typeof (timeObj === null || timeObj === void 0 ? void 0 : timeObj.position) !== 'undefined') {
                if (timeObj.position > currentMedia.quartileBenchmarks[75]
                    && currentMedia.quartile === 75) {
                    gaSendStandardEvent('Quartile 75%');
                    mediaState.update({ quartile: 100 });
                }
                else if (timeObj.position > currentMedia.quartileBenchmarks[50]
                    && currentMedia.quartile === 50) {
                    gaSendStandardEvent('Quartile 50%');
                    mediaState.update({ quartile: 75 });
                }
                else if (timeObj.position > currentMedia.quartileBenchmarks[25]
                    && currentMedia.quartile === 25) {
                    gaSendStandardEvent('Quartile 25%');
                    mediaState.update({ quartile: 50 });
                }
            }
            // Reset this so the variable does not
            // grow to an outrageously high number.
            timeCount = 0;
        }
    });
    /**
     * Fired when the player toggles to/from fullscreen.
     * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronfullscreen
     */
    player.on('fullscreen', function () {
        if (player.getFullscreen()) {
            gaSendStandardEvent('Video Fullscreen');
        }
        else {
            gaSendStandardEvent('Video Windowscreen');
        }
    });
    /**
     * Triggered when the player's volume has changed.
     * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronvolume
     */
    player.on('volume', function () {
        gaSendStandardEvent('Video Volume');
        if (player.getMute()) {
            gaSendStandardEvent('Video Volume Mute Disabled');
        }
    });
    /**
     * Triggered when the player has gone in or out of a mute state.
     * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronmute
     *
     * @param obj muteState The player's new mute state.
     */
    player.on('mute', function (muteState) {
        gaSendStandardEvent("Mute ".concat((muteState === null || muteState === void 0 ? void 0 : muteState.mute) ? 'Enabled' : 'Disabled'));
    });
    /**
     * Fires each time the end of the video is reached.
     * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeroncomplete
     */
    player.on('complete', function () {
        // Send a standardized GA event.
        gaSendStandardEvent('Quartile 100%');
    });
    /**
     * Social Sharing Events.
     */
    var sharingPlugin = player.getPlugin('sharing');
    if (typeof sharingPlugin !== 'undefined') {
        /**
         * Share overlay open.
         * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#sharingpluginopen
         */
        sharingPlugin.on('open', function () {
            gaSendStandardEvent('Share Overlay Open');
        });
        /**
         * Share overlay click.
         * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#sharingpluginonclick
         */
        sharingPlugin.on('click', function () {
            gaSendStandardEvent('Share Overlay Click');
        });
        /**
         * Share overlay closed.
         * Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#sharingpluginclose
         */
        sharingPlugin.on('close', function () {
            gaSendStandardEvent('Share Overlay Closed');
        });
    }
}
