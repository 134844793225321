/**
 * Set the Ad Response Error custom dimension.
 * This custom dimension isn't applicable to ALL players, so we
 * will set the dimension based on the player event.
 * In the GA Account this is labeled as `Video Ad Response Error`.
 * Custom Dimension: 33.
 *
 * @param {mixed} message The message assigned to the custom dimension.
 * @return {object} The object shaped for setting custom dimensions based on the key.
 */
export default function adResponseError(message) {
    if (message === void 0) { message = null; }
    return {
        dimension33: message,
    };
}
