var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * Return a super simple object with its own getter and setter
 *
 * @param {Any} initial Initial value
 * @return {object}
 */
export default function createSimpleGetterSetter(initial) {
    var getterSetter = {
        value: initial,
        get: function () { return getterSetter.value; },
        set: function (newVal) {
            getterSetter.value = newVal;
        },
        update: function (updateVal) {
            var current = getterSetter.get();
            getterSetter.set(__assign(__assign({}, current), updateVal));
        },
    };
    return getterSetter;
}
