/**
 * Player Ad Status.
 * Determine if player is ad enabled.
 * Possible values: `yes` or `no`.
 * In the GA Account this is labeled as `Video Player Ad Status`.
 * Custom Dimension: 35.
 *
 * @param {string} status The status of an Ad Enabled player.
 * @return {object} The object shaped for setting custom dimensions based on the key.
 */
export default function playerAdStatus(status) {
    if (status === void 0) { status = 'no'; }
    return {
        dimension35: status,
    };
}
