/**
 * Events:
 *  Player Load
 *  PIP Enabled
 *  PIP Disabled
 */
import { gaSendEvent } from '@/client/js/analytics/googleAnalytics';
import { gaSendStandardEvent } from '@/platform/jw-player/client/js/analytics/gaActions';
import { getPermalinkConfig } from '@/platform/jw-player/client/js/analytics/utils';
/**
 * Integrate analytics on JW Player Events.
 * NOTE: getConfig() and getFloating are built-in JW Player methods.
 *
 * @param player The JW Player instance.
 */
export default function playerEvent(player) {
    var playerConfig = player.getConfig();
    // Send GA PLAYER LOAD event with assigned Category and Action.
    gaSendEvent('Player Load', playerConfig.pid || '', getPermalinkConfig());
    // Send the floating player events.
    // Ref: https://developer.jwplayer.com/jwplayer/docs/jw8-javascript-api-reference#jwplayeronfloat
    player.on('float', function () {
        if (player.getFloating()) {
            gaSendStandardEvent('PIP Enabled');
        }
        else {
            gaSendStandardEvent('PIP Disabled');
        }
    });
}
