/**
 * Player Autoplay Status.
 * Possible values: `yes` or `no`.
 * In the GA Account this is labeled as `Video Player Autoplay Status`.
 * Custom Dimension: 36.
 *
 * @param {object} player The JW Player instance.
 * @return {object} The object shaped for setting custom dimensions based on the key.
 */
export default function playerAutoplayStatus(player) {
    var autostartConfig = player.getConfig();
    return {
        dimension36: (autostartConfig === null || autostartConfig === void 0 ? void 0 : autostartConfig.autostart) || false,
    };
}
