/**
 * Sent tracking event to GA.
 *
 * @param {String} category Event Category.
 * @param {String} action   Event Action.
 * @param {String} label    Event Label.
 * @param {String} value    Event Value.
 */
export function gaSendEvent(category, action, label, value) {
    if (action === void 0) { action = null; }
    if (label === void 0) { label = null; }
    if (value === void 0) { value = null; }
    if (typeof window.gtag !== 'undefined') {
        var fields = {
            eventCategory: category ? category.toString() : 'Undefined Category',
            eventAction: action ? action.toString() : 'Undefined Action',
            eventLabel: label ? label.toString() : window.location.href,
        };
        if (value || value === 0) {
            fields.eventValue = value;
        }
        // Add to GA Event tracker queue.
        // TODO: Implement GA4 tracking here or remove?
    }
    else {
        // eslint-disable-next-line
        console.error('Unable to send Video Player Events. Google Analytics queue not available.');
    }
}
/**
 * Set GA custom dimension. Parameter format:
 *
 * {
 *   dimension1: 'dimension 1 value',
 * }
 *
 * @param {Object} dimensionObj The dimension object to set.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function gaCustomDimension(dimensionObj) {
    if (dimensionObj === void 0) { dimensionObj = {}; }
    if (typeof window.gtag !== 'undefined') {
        // TODO: Implement GA4 tracking here or remove?
    }
}
