/**
 * Media ID.
 * In the GA Account this is labeled as `Video ID`.
 * Custom Dimension: 22.
 *
 * @param {Object} mediaObject The media loaded in to the player.
 * @return {object} The object shaped for setting custom dimensions based on the key.
 */
export default function mediaId(media) {
    if (media === void 0) { media = {}; }
    var idDimension = null;
    if (typeof (media === null || media === void 0 ? void 0 : media.mediaid) !== 'undefined') {
        // Playlist item media have a different object
        // shape compared to the lead video.
        idDimension = media.mediaid;
    }
    return {
        dimension22: idDimension,
    };
}
