import { getPublishDate } from '@/platform/jw-player/client/js/analytics/utils';
/**
 * Media Publish Date.
 * In the GA Account this is labeled as `Video Publish Date`.
 * Custom Dimension: 23.
 *
 * @param {Object} mediaObject The media loaded in to the player.
 */
export default function mediaPublishDate(mediaObject) {
    if (mediaObject === void 0) { mediaObject = {}; }
    var mediaDate = getPublishDate((mediaObject === null || mediaObject === void 0 ? void 0 : mediaObject.item) || mediaObject, false);
    return {
        dimension23: mediaDate,
    };
}
