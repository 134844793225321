import createSimpleGetterSetter from '@/client/js/utils/getterSetter';
/**
 * Track the currently loaded video in the player and provide sensible default property values.
 *
 * @type {{set: function(*): void, get: function(): Any, update: function(*): void, value: Any}|*}
 */
// eslint-disable-next-line import/prefer-default-export
export var mediaState = createSimpleGetterSetter({
    playCount: 0, // Used for setting GA Custom Dimension 26 to identify `Video Player Type`.
});
