import createSimpleGetterSetter from '@/client/js/utils/getterSetter';
/**
 * Track the GA custom dimensions.
 *
 * @type {{set: function(*): void, get: function(): Any, update: function(*): void, value: Any}|*}
 */
// eslint-disable-next-line import/prefer-default-export
export var cdState = createSimpleGetterSetter({
    media: {},
    player: {}, // Custom dimensions pertaining to the player.
});
