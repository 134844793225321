import { millisecondDate, formatPublishDate, } from '@/client/js/utils/dateFormat';
import { configState } from '@/platform/jw-player/client/js/analytics/state/configState';
/**
 * Get the publish date of a media item.
 *
 * @param media The Media item object.
 * @returns {string}
 */
export function getPublishDate(media, trimFormat) {
    if (media === void 0) { media = {}; }
    if (trimFormat === void 0) { trimFormat = true; }
    // Set the default value for the publish date.
    var dateResult = '';
    if (media === null || media === void 0 ? void 0 : media.pubdate) {
        var publishDate = millisecondDate(media.pubdate);
        // Validate the date.
        if (publishDate !== '') {
            // Format: YYYY-MM-DD.
            dateResult = publishDate;
        }
    }
    else if (media === null || media === void 0 ? void 0 : media.created) {
        var createdDate = new Date(media.created);
        dateResult = createdDate.toGMTString();
    }
    if (trimFormat) {
        dateResult = formatPublishDate(dateResult);
    }
    return dateResult !== '' ? dateResult : 'no date';
}
/**
 * Get the permalink of a post based on the plugin config permalink property.
 *
 * @returns {string}
 */
export function getPermalinkConfig() {
    var pluginConfig = configState.get();
    return typeof pluginConfig.permalink !== 'undefined'
        ? encodeURI(pluginConfig.permalink)
        : encodeURI(window.location.href); // phpcs:ignore WordPressVIPMinimum.JS.Window.location
}
/**
 * Determine if the user is viewing the media in a mobile device.
 *
 * @param {Any} player The JW Player instance.
 * @return {boolean}
 */
export default function isMobile(player) {
    var _a;
    var environment = player.getEnvironment();
    return ((_a = environment === null || environment === void 0 ? void 0 : environment.OS) === null || _a === void 0 ? void 0 : _a.mobile) || false;
}
/**
 * Set an object for quartile tracking based on media duration.
 *
 * @param duration The full duration length of a media item in seconds.
 * @returns {{"25": number, "50": number, "75": number}}
 */
export function quartileTracking(duration) {
    if (duration === void 0) { duration = 0; }
    return {
        25: parseInt(duration * 0.25, 10),
        50: parseInt(duration * 0.50, 10),
        75: parseInt(duration * 0.75, 10),
    };
}
