/**
 * Format a date string to YYYY-MM-DD.
 *
 * @param date A GMT date string (ie Mon, 01 Feb 2022 12:00:00 GMT)
 * @returns {string} A formatted date string.
 */
export function formatPublishDate(date) {
    var gmtDate = new Date(date);
    var month = gmtDate.getMonth() + 1;
    var day = gmtDate.getDate();
    // Condition month to prepend with leading zero.
    if (month < 10) {
        month = "0".concat(month);
    }
    // Condition day to prepend with leading zero.
    if (day < 10) {
        day = "0".concat(day);
    }
    return "".concat(gmtDate.getFullYear(), "-").concat(month, "-").concat(day);
}
/**
 * Get EST date and time format from milliseconds.
 *
 * @param utcSeconds Milliseconds from epoch.
 * @returns {string|string}
 */
export function millisecondDate(utcSeconds) {
    if (utcSeconds === void 0) { utcSeconds = 0; }
    var publishDate = new Date(utcSeconds * 1000); // `0` sets the date to epoch time.
    return !Number.isNaN(publishDate) ? publishDate.toDateString() : '';
}
