import { gaSendEvent } from '@/client/js/analytics/googleAnalytics';
import { getPermalinkConfig, getPublishDate } from '@/platform/jw-player/client/js/analytics/utils';
import { mediaState } from '@/platform/jw-player/client/js/analytics/state/mediaState';
/**
 * Conform to a standardized theme based
 * action value for Google Analytics.
 *
 * @param {object} The playlist item media object.
 * @returns {string}
 */
export function setGaAction(itemObj) {
    var mediaItems = [];
    // Assign the title.
    if (itemObj === null || itemObj === void 0 ? void 0 : itemObj.title) {
        mediaItems.push(itemObj.title);
    }
    // Assign the media length.
    if (itemObj === null || itemObj === void 0 ? void 0 : itemObj.duration) {
        mediaItems.push("".concat(parseInt(itemObj.duration, 10), " seconds"));
    }
    else {
        mediaItems.push('no duration');
    }
    // Assign and format the published date.
    mediaItems.push(getPublishDate(itemObj));
    return mediaItems.join(' | ');
}
/**
 * Create function for sending events with
 * consistent eventAction and eventLabel.
 *    category: an identifier - ie: `Video Start`.
 *    action: delimited media information - `name | duration | VIDEO publish date`.
 *    label: NYP, P6, or Decider or window location as a fallback.window.location.href
 *
 * @return {Function}
 */
export function gaSendStandardEvent(category, value) {
    if (value === void 0) { value = null; }
    var media = mediaState.get();
    gaSendEvent(category, (media === null || media === void 0 ? void 0 : media.gaAction) ? media.gaAction : '', getPermalinkConfig(), value);
}
