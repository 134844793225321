/**
 * Player Embed Location.
 * In the GA Account this is labeled as `Video Embed Location`.
 * Custom Dimension: 43.
 *
 * @param {object} player The JW Player instance.
 * @return {object} The object shaped for setting custom dimensions based on the key.
 */
export default function playerEmbedLocation(player) {
    if (player === void 0) { player = null; }
    var playerConfig = player.getConfig();
    var location = '';
    if (document.querySelector(".featured-image #".concat(playerConfig.id))) {
        location = 'featured';
    }
    else if (document.querySelector(".entry-content #".concat(playerConfig.id))) {
        location = 'content';
    }
    return {
        dimension43: location,
    };
}
