import { mediaState } from '@/platform/jw-player/client/js/analytics/state/mediaState';
/**
 * Player Type.
 * Possible values: `video landing page` (lead video) or `playlist`.
 * In the GA Account this is labeled as `Video Player Type`.
 * Custom Dimension: 26.
 *
 * @return {object} The object shaped for setting custom dimensions based on the key.
 */
export default function playerType() {
    var _a;
    return {
        dimension26: ((_a = mediaState === null || mediaState === void 0 ? void 0 : mediaState.value) === null || _a === void 0 ? void 0 : _a.playCount) === 1 ? 'video landing page' : 'playlist',
    };
}
