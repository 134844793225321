import { playerState } from '@/platform/jw-player/client/js/analytics/state/playerState';
/**
 * Player Video Start Count.
 * Assign the number of times a media item has been started in the same player.
 * In the GA Account this is labeled as `Player Video Start Count`.
 * Custom Dimension: 38.
 *
 * @return {object} The object shaped for setting custom dimensions based on the key.
 */
export default function playerMediaStartCount() {
    var _a;
    return {
        dimension38: ((_a = playerState === null || playerState === void 0 ? void 0 : playerState.value) === null || _a === void 0 ? void 0 : _a.playCount) || 0,
    };
}
